import React, { FC } from 'react';
import { graphql, Link } from 'gatsby';

import { ISuccessfulSexPageNavigationComponentProps } from './models';
import './SuccessfulSexPageNavigation.scss';

const SuccessfulSexPageNavigation: FC<ISuccessfulSexPageNavigationComponentProps> = ({
  sideNavigation: [
    {
      properties: { sideNavigationName, sideNavigationMenu },
    },
  ],
}) => (
  <div className="successfull-sex-page-navigation" data-testid="successfull-sex-page-navigation">
    <h4 className="successfull-sex-page-navigation__title">{sideNavigationName}</h4>
    <ul className="successfull-sex-page-navigation__list">
      {sideNavigationMenu.map(({ name, url }) => (
        <li key={name}>
          <Link activeClassName="active" className="successfull-sex-page-navigation__link" to={url}>
            {name}
          </Link>
        </li>
      ))}
    </ul>
  </div>
);

export default SuccessfulSexPageNavigation;

export const query = graphql`
  fragment FragmentSuccessfulSexPageNavigation on SideNavigationTypes {
    properties {
      sideNavigationMenu {
        name
        url
      }
      sideNavigationName
    }
  }
`;
