import { graphql } from 'gatsby';
import React, { FC } from 'react';

import { Container, Row, Col, Layout } from 'layout';
import Banner from 'components/Banner';
import GatsbyImage from 'common/GatsbyImage';
import Video from 'components/Video';
import SuccessfulSexPageNavigation from 'components/SuccessfulSexPageNavigation';

import { ISuccessfulSexDocumentationPageComponentProps } from './models';
import './SuccessfulSexDocumentationPage.scss';

const SuccessfulSexDocumentationPage: FC<ISuccessfulSexDocumentationPageComponentProps> = ({
  data: {
    umbracoSuccessfulSexPageDocumentation: {
      banner,
      bestPhotoSection,
      description,
      sideNavigation,
      title: pageTitle,
      videoSection,
      defaultCompositions,
      seoMetaKeywords,
      seoMetaTitle,
      seoMetaDescription,
      seoExternalHreflangs,
      url,
    },
  },
}) => {
  return (
    <Layout
      defaultCompositions={defaultCompositions}
      seo={{
        seoMetaKeywords,
        seoMetaTitle,
        seoMetaDescription,
        seoExternalHreflangs,
      }}
      className="Successful-sex-documentation"
    >
      <Container fluid>
        {banner.map(({ properties: { title, ...restBannerProperties } }) => (
          <Banner
            key={title}
            url={url}
            title={title}
            {...{ ...restBannerProperties, image: restBannerProperties.localImage }}
          />
        ))}
        <Container>
          <div className="container__inside">
            <Row>
              <Col lg="4" xl="4">
                <SuccessfulSexPageNavigation {...{ sideNavigation }} />
              </Col>
              <Col sm="12" md="12" lg="8" xl="8">
                <p className="successful-sex-documentation__description">{description}</p>
              </Col>
              {pageTitle ? (
                <Col sm="12">
                  <h2 className="successful-sex-documentation__title">{pageTitle}</h2>
                </Col>
              ) : null}
              {bestPhotoSection ? (
                <Col sm="12">
                  <div className="successful-sex-documentation__images">
                    {bestPhotoSection.map(({ properties: { image, imageAltLabel } }) => (
                      <GatsbyImage
                        key={imageAltLabel}
                        isLazyLoading={false}
                        fluid={image?.childImageSharp.fluid}
                        alt={imageAltLabel}
                      />
                    ))}
                  </div>
                </Col>
              ) : null}
              {videoSection ? (
                <Col sm="12">
                  <div className="successful-sex-documentation__video">
                    {videoSection.map(({ properties: { videoLink, localImage } }, videoIndex) => (
                      <Video
                        key={`video-${videoLink || videoIndex}`}
                        className="survey-page__video"
                        thumbnail={localImage}
                        videoURL={videoLink}
                      />
                    ))}
                  </div>
                </Col>
              ) : null}
            </Row>
          </div>
        </Container>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query SuccessfulSexDocumentationPage($url: String = "") {
    umbracoSuccessfulSexPageDocumentation(url: { eq: $url }) {
      url
      seoMetaTitle
      seoMetaDescription
      seoMetaKeywords
      seoExternalHreflangs {
        hreflang: key
        href: value
      }
      defaultCompositions {
        ...DefaultCompositionsFragment
      }
      banner {
        properties {
          title
        }
      }
      videoSection {
        properties {
          videoLink
          localImage {
            id
            childImageSharp {
              fluid(maxWidth: 1400, quality: 95) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          imageAlt
        }
      }
      sideNavigation {
        properties {
          sideNavigationName
          sideNavigationMenu {
            name
            url
          }
        }
      }
      title
      description
      url
      bestPhotoSection {
        properties {
          imageAltLabel
          image {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;

export default SuccessfulSexDocumentationPage;
